@import url("https://fonts.googleapis.com/css2?family=Convergence&display=swap");

* {
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.main {
  height: 100%;
  font-family: "Convergence", sans-serif;
  overflow: hidden;
}

.bg {
  height: 100%;
  width: 100%;
  filter: brightness(50%);
}

.panel {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 400px;
  background-color: #2e2e2ed2;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

header {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: right;
  position: absolute;
  top: 0;
  left: 0;
}

header div {
  position: relative;
  bottom: 10px;
  background-color: #a59051;
  border-left: 3px solid #bcac85;
  border-bottom: 3px solid #bcac85;
  border-right: 3px solid #bcac85;
  border-radius: 0px 0px 15px 15px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

header div:hover {
  box-shadow: 0 7px 50px 10px #000000aa;
  cursor: pointer;
}

.leftlink {
  transition: 0.5s;
  transform: rotate(-7deg);
  right: 50px;
}

.rightlink {
  transition: 0.5s;
  transform: rotate(-3deg);
  right: 40px;
}

.leftlink:hover {
  z-index: 10;
}

.rightlink:hover {
  z-index: 10;
}

header div a {
  text-decoration: none;
  color: rgb(255, 240, 157);
  font-size: 18px;
}

.top {
  height: 300px;
  width: 100%;
  display: flex;
}

.bottom {
  width: 100%;
  height: 200px;
}

.left {
  width: 300px;
  height: 100%;
}

.left img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  margin-left: 50px;
}

.right {
  width: 700px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.welcome {
  color: rgb(254, 255, 255);
  text-align: center;
  word-wrap: break-word;
  padding: 30px;
}

.settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60%;
}

.settings label {
  color: rgb(154, 238, 231);
  font-size: 22px;
}

.settings input {
  width: 50%;
  -webkit-appearance: none;
  border-radius: 100px;
  background-color: rgb(138, 138, 138);
}

input[type="range"]::-moz-range-thumb {
  border: 1px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: #00c3ff;
  cursor: pointer;
}

.setting {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.audiosection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.setting span {
  color: rgb(240, 234, 131);
  font-size: 30px;
}

.register {
  position: absolute;
  left: 50%;
  color: aliceblue;
  font-size: 40px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.footer {
  z-index: 1;
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.footer p {
  color: rgb(255, 255, 255);
  font-size: 18px;
}
